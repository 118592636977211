import React from 'react';
import { Link } from 'react-router-dom';


class Footer extends React.Component {
  render() {
    return (
      <div className="Footer">
        <span>&copy; 2025 Wabi.</span>

        <div className="Footer__right">
          <Link to="/terms" target="__blank">Terms</Link>
          <Link to="/privacy" target="__blank">Privacy</Link>
        </div>
      </div>
    );
  }
}

export default Footer;
